<template>
  <a-spin :spinning="spinning">
<a-card :bordered="false">
  <div class="realtor">
    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
      <a-row :gutter="8">
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="大区事务所">
            <a-cascader
              :options="dealerList"
              v-model="regionalOffice"
              change-on-select
              placeholder="请选择大区/事务所"
              @change="onChange"
              :field-names="{ label: 'title', value: 'id', children: 'children' }"
            />
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="经销商名称">
            <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="折扣名称">
            <a-input v-model="searchData.rebateName" placeholder="请输入折扣名称" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="审核状态">
            <DictSelect field="verifyStatus" :value.sync="searchData.verifyStatus" style="width: 100%" placeholder="审核状态"></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="初始结束时间">
            <DateRange :startTime.sync="searchData.startBeforeTime" :endTime.sync="searchData.endBeforeTime"></DateRange>

          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="申请结束时间">
            <DateRange :startTime.sync="searchData.startApplyTime" :endTime.sync="searchData.endApplyTime"></DateRange>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="申请日期">
            <DateRange :startTime.sync="searchData.startCreateTime" :endTime.sync="searchData.endCreateTime"></DateRange>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div>
      <a-button
        v-if="isSubmit && selectedRows.length > 0 && selectedRows[0].verifyStatus == 0"
        type="primary"
        @click="batchSubmitAudit"
      >提交审核</a-button>
      <a-button
        v-if="isAudit && selectedRows.length > 0 && selectedRows[0].verifyStatus == 1 && selectedRows[0].taskId != null"
        type="primary"
        @click="toHandlerAll"
      >审核</a-button>
      <a-button
        v-if="isReturn && selectedRows.length == 1 && selectedRows[0].verifyStatus == 2"
        type="primary"
        @click="toHandler('returnStatus')"
        icon="rollback"
      >返审</a-button>
      <a-button v-if="isDelete && selectedRows.length == 1 && selectedRows[0].verifyStatus == 0" type="primary" @click="toHandler('del')">删除</a-button>
      <downLoad
        v-if="isExport"
        method="post"
        @downLoadDone="downLoadDone"
        @startDownLoad="startDownLoad"
        api="/api/dealer/dealer/dealerRebateApply/excelExport"
        :params="exportSearchData"
        name="折扣延时申请记录.xls">导出</downLoad>
    </div>
    <!-- 数据表格 -->
      <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
               :columns="columns" :data-source="tableData" bordered :pagination="page"
               @change="changeTable" :rowClassName="rowClassName" :customRow="changeTableRow">

      <span slot="verifyStatus" slot-scope="text">
          <a-tag v-if="text == 0" color="#87d068">待处理</a-tag>
          <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
          <a-tag v-if="text == 3" color="#ff7875">审核拒绝</a-tag>
      </span>
      <span slot="rebateAmount" slot-scope="text"> {{text | formatMoney}} </span>
      <span slot="remainingAmount" slot-scope="text"> {{text | formatMoney}} </span>
    </a-table>

    <a-modal title="审核" width="40%" :visible="visibleAll" :footer="null" @cancel="visibleAll = false" :maskClosable="false">
      <a-form-model ref="formCharge" :model="rowDataCharge" :rules="formRule" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="经销商编码" prop="dealerCode" v-if="applyClassify == 1">
          <a-input v-model="rowDataCharge.dealerCode" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="审核备注">
          <a-textarea v-model="rowDataCharge.verifyRemark"></a-textarea>
        </a-form-model-item>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="handleCanceAll">取消</a-button>
        <a-button type="danger"  :loading="loadingAll"  @click="onAuditAll('refuse')">拒绝</a-button>
        <a-button type="primary" :loading="loadingAll"  @click="onAuditAll('audit')">通过</a-button>
      </div>
    </a-modal>

  </div>
</a-card>
  </a-spin>
</template>

<script>
import { delRebateApply, listRebateApplyList, returnStatus} from './api/DealerRebateApi'
import { checkPermission } from '@/utils/permissions'
import {auditTaskBatch} from '@/api/activiti';

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'customerInfo',
  components: {

  },
  data() {
    return {
      columns:[
        {
          title: '事务所',
          dataIndex: 'firmName',
          key: 'firmName',
          align:'center',
        },
        {
          title: '经销商',
          dataIndex: 'dealerName',
          key: 'dealerName',
          align:'center',
        },
        {
          title: '折扣名称',
          dataIndex: 'rebateName',
          key: 'rebateName',
          align:'center',
        },
        {
          title: '折扣金额',
          dataIndex: 'rebateAmount',
          key: 'rebateAmount',
          align:'center',
          scopedSlots: { customRender: 'rebateAmount' }
        },
        {
          title: '剩余金额',
          dataIndex: 'remainingAmount',
          key: 'remainingAmount',
          align:'center',
          scopedSlots: { customRender: 'remainingAmount' }
        },
        {
          title: '初始结束时间',
          dataIndex: 'beforeTime',
          key: 'beforeTime',
          align:'center',
        },
        {
          title: '申请结束时间',
          dataIndex: 'applyTime',
          key: 'applyTime',
          align:'center',
        },
        {
          title: '审核状态',
          dataIndex: 'verifyStatus',
          key: 'verifyStatus',
          align:'center',
          scopedSlots: { customRender: 'verifyStatus'}
        },
        {
          title: '申请人',
          dataIndex: 'createUser',
          key: 'createUser',
          align:'center',
        },
        {
          title: '申请时间',
          dataIndex: 'createTime',
          key: 'createTime',
          align:'center',
        },

       ],
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
              current: 1,
              pageSize: 10,
              total: 0,
              showSizeChanger: true,
              showTotal: () => `共${this.page.total}条`,
            },
      selectedRowKeys: [],
      selectedRows: [],
      statusList: [],
      item: [],
      isSubmit: checkPermission('cus:rebate:apply:submit'),
      isAudit: checkPermission('cus:rebate:apply:audit'),
      isDelete: checkPermission('cus:rebate:apply:delete'),
      isReturn: checkPermission('cus:rebate:apply:return'),
      isExport: checkPermission('cus:rebate:apply:export'),
      dealerList: [],
      regionalOffice: [],
      visibleAll:false,
      loading: false,
      loadingAll: false,
      rejectReason: '',
      verifyRemark:'',
      rowDataCharge:{},
      formRule: {
        dealerCode:[{ required: true, message: '请填写经销商编码', trigger: 'blur' }],
      },
      applyClassify:'',
      ranges:{
        '近一周': [moment().subtract(1, 'weeks'), moment()],
        '近一月': [moment().subtract(1, 'months'), moment()],
        '近一年': [moment().subtract(1, 'years'), moment()],
        '本周': [moment().startOf('week'), moment()],
        '本月': [moment().startOf('month'), moment()],
        '本年': [moment().startOf('year'), moment()]
      },
      spinning:false
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: false,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        }
      }
    },
    exportSearchData(){
      return {
        ...this.searchData,
        ids:this.selectedRowKeys
      }
    }
  },
  methods: {
    startDownLoad(){
      this.spinning = true
    },
    downLoadDone (){
      this.spinning = false
    },
    // 隔行换色
    rowClassName(record,index) {
        let className = "light-row"
        if (index % 2 === 1) className = "dark-row"
        return className;
    },
    /**
     * 获取表格数据
     */
    getData(num) {
      if(!num){
         this.selectedRows = []
      this.selectedRowKeys = []
      }
      this.tableLoading = true
      //此页面只查询报备经销商经销商
      listRebateApplyList({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

        this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
          // 获取大区事务所经销商数据
          this.dealerList = res.body
        })
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1;
      this.page.pageSize = 10
      this.getData()
    },

    onChange(val) {
      this.searchData.bigAreaId = val[0]
      this.searchData.firmId = val[1]
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      this.page.current = 1;
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData(1)
    },
    handleChange(value) {},
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: e => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          }
        }
      }
    },

    /**
     * 批量提交审核
     */
    batchSubmitAudit() {
      var _this = this
      const everyResult = this.selectedRows.every((item) => {
        return item.verifyStatus === 0
      })
      if (everyResult) {
        this.dataList = this.selectedRows
        _this.$confirm({
          title: '提交审核',
          content: '确认要提交审核吗?',
          okText: '确认',
          cancelText: '取消',
          async onOk() {
            _this.tableLoading  = true
            const query = {
              ids : _this.dataList.map((item) => item.id)
            }
            _this.axios.post('/api/dealer/dealer/dealerRebateApply/batchSubmitAudit', query).then(res => {
              if(res.code == 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              }else {
                _this.$notification.error({ message: res.message })
                _this.getData()
              }

            })
              .finally(() => (_this.tableLoading = false))
          },
          onCancel() {
          }
        })
      }else{
        this.$notification.error({ message: '请选择未提交审核记录' })
      }
    },

    // 批量审核弹框
    toHandlerAll() {
      let everyResult = this.selectedRows.every((item) => {
        return item.verifyStatus === 1 && item.taskId != null
      })
      if (everyResult) {
        this.visibleAll = true;
        this.dataList = this.selectedRows
        this.applyClassify = this.selectedRows[0].applyClassify
      }else{
        this.$notification.error({ message: '请选择相同待审核记录' })

      }
    },

    onAuditAll(name) {
      this.$refs.formCharge.validate(async (valid) => {
        if (!valid) {
          return
        }
        const _this = this
        _this.loadingAll = true
        let result = 2
        if (name == 'refuse') {
          result = 3
        }
        auditTaskBatch({
          taskIds: _this.dataList.map((item) => item.taskId),
          result: result,
          comment: _this.rowDataCharge.verifyRemark || "",
          chargeValue: _this.rowDataCharge.dealerCode || ""
        }).then((res) => {
          if (res.code == 200) {
            setTimeout(() => {
              _this.$message.success('审核成功')
              _this.getData()
              _this.rowDataCharge.verifyRemark = ''
              _this.rowDataCharge.dealerCode = ''
              _this.visibleAll = false
              _this.loadingAll = false
            }, 3000);
          } else {
            _this.$message.error(res.message)
            _this.getData()
            _this.rowDataCharge.verifyRemark = ''
            _this.rowDataCharge.dealerCode = ''
            _this.visibleAll = false
            _this.loadingAll = false
          }
        })
      })
    },

    handleCanceAll(){
      this.visibleAll = false
      this.rowDataCharge.verifyRemark = ''
      this.loadingAll = false
      this.rowDataCharge.dealerCode = ''
    },

    toHandler(name) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              console.log(_this.selectedRows[0])
              const res = await delRebateApply(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'returnStatus':
          _this.$confirm({
            title: '返审警告',
            content: '确认要将此条记录返审至待处理状态吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await returnStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },

  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
        background: #FFF!important;;
    }
    ::v-deep .ant-table-tbody > .dark-row {
        background: #fafafa!important;
    }
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>
